export const id_firebase = "bczgbmjGJ9nmGTMZ2V3b"

export const ids_firebase = [
    {
        "id": "bczgbmjGJ9nmGTMZ2V3b",
        "href": "veiculo_attra"
    },
    {
        "id": "vJqIJ1rGXckE5NOBUzPP",
        "href": "veiculo_pissarelli"
    },
    {
        "id": "vkSX4w5KEcQPb5XLbvtl",
        "href": "veiculo_automotors"
    },
    {
        "id": "JUK0HkhCTFuFtyfp1HaC",
        "href": "veiculo_avenida"
    },
    {
        "id": "lLFJ0emzWz4WkRFt5iDY",
        "href": "veiculo_divel"
    },
    {
        "id": "lfWdLVFtJdfzE5V8Ydgb",
        "href": "veiculo_edcar"
    },
    {
        "id": "lHRfo2VvUs9R0hHWptg2",
        "href": "veiculo_afcustoms"
    },
    {
        "id": "kPUFW4aTB0wrZNE7LTvU",
        "href": "veiculo_astro"
    },
    {
        "id": "hvqxujjW5TNtcDHUOahJ",
        "href": "veiculo_atacaar"
    },
    {
        "id": "AJaxwEeyRHFT0mJSXszX",
        "href": "veiculo_canever"
    },
    {
        "id": "DOXYBLi87Wefi8PNSjwq",
        "href": "veiculo_evolution"
    },
    {
        "id": "MLVaKMzAJS3hlSdQBWUV",
        "href": "veiculo_gbs"
    },
    {
        "id": "lvVNp4wH7OkAST6APO2r",
        "href": "veiculo_lucas"
    },
    {
        "id": "2Jc5Cv0NmR0zoJKOD7mE",
        "href": "veiculo_maverick"
    },
    {
        "id": "OtkwZKeDKX4GYJmcObfu",
        "href": "veiculo_premium"
    },
    {
        "id": "uLYQKAJn5Ke8T0s1u1jW",
        "href": "veiculo_spiner"
    },
    {
        "id": "8cGlcWDnEIIuUUgQxnmJ",
        "href": "veiculo_tops"
    },
    {
        "id": "4uby3vcGvCNzo1fv90Mh",
        "href": "veiculo_jbveiculos"
    },
    {
        "id": "nOoeourgfCAANjggNy0N",
        "href": "veiculo_kalunga"
    },
    {
        "id": "8isHQPx377Q03D02IhxV",
        "href": "veiculo_m7imports"
    },
    {
        "id": "Aas2iWbAxHZaJc8GY3SN",
        "href": "veiculo_novastatus"
    },
    {
        "id": "XW84OIf45tE2UOF2GERX",
        "href": "veiculo_quintela"
    },
    {
        "id": "q3TP6UZz3D3qG6KV78gk",
        "href": "veiculo_rrveiculos"
    },
    {
        "id": "7hSxjfCiCNHHIEINxmvr",
        "href": "veiculo_tsmotors"
    },
    {
        "id": "SspxGhbQd8HB4bi4Pao9",
        "href": "veiculo_zemaria"
    },
    {
        "id": "vg64EWRHPLMUaR3l0kXM",
        "href": "veiculo_a2motors"
    },
    {
        "id": "QUobEo3q4ozZ9iF1kIOe",
        "href": "veiculo_a2x"
    },
    {
        "id": "q9FOR3andLp7GxvJrRWR",
        "href": "veiculo_amaral"
    },
    {
        "id": "k571HQiej42eAH6xECQK",
        "href": "veiculo_ancora"
    },
    {
        "id": "DkrgGTUFXe2S5Bj9eXcK",
        "href": "veiculo_anri"
    },
    {
        "id": "AN1cXrqbkkrtzV0XcxSS",
        "href": "veiculo_ataque"
    },
    {
        "id": "2Md0I3MeXPyvJ1saM9VH",
        "href": "veiculo_atual"
    },
    {
        "id": "uFpX7Qq7qWNs55E9rpLJ",
        "href": "veiculo_automais"
    },
    {
        "id": "Yh9WubjmWC5vtFFf2Xli",
        "href": "veiculo_autonunes"
    },
    {
        "id": "d7xWBZ3lRjdr1TI8Gb6g",
        "href": "veiculo_autopremium"
    },
    {
        "id": "gTr4J59EsvmqwDtrjhmF",
        "href": "veiculo_brasilautomoveis"
    },
    {
        "id": "nA32pvElB2AKyeeR2Hvx",
        "href": "veiculo_brasilveiculos"
    },
    {
        "id": "l0n4rFd4vsQDO6p4zSnI",
        "href": "veiculo_capitalveiculos"
    },
    {
        "id": "6O4uxov2t2bNqFZx8abk",
        "href": "veiculo_centralmotors"
    },
    {
        "id": "TlZLjdFxwdblsGisXq3q",
        "href": "veiculo_citycarauto"
    },
    {
        "id": "xLzElLW4txqaCVsuS8sR",
        "href": "veiculo_citycarmultimarcas"
    },
    {
        "id": "j28AXiUSqnzT9d7DJqWU",
        "href": "veiculo_confianca"
    },
    {
        "id": "rIFGI01RIdHYB0rcz78K",
        "href": "veiculo_dakar"
    },
    {
        "id": "q6OOhadWCJqOEGl4kuAr",
        "href": "veiculo_dmotors"
    },
    {
        "id": "XZQQe9E1966zcHrBsCLw",
        "href": "veiculo_dosecar"
    },
    {
        "id": "nJjkyTA4nuGWywVutOGu",
        "href": "veiculo_enzomotors"
    },
    {
        "id": "ov0lJzPZoG6zGrYmtdNT",
        "href": "veiculo_euripedes"
    },
    {
        "id": "EkCfV17Kewam3P1FZruM",
        "href": "veiculo_fortemultimarcas"
    },
    {
        "id": "TAi5Akrc7h3lpqzmRZMW",
        "href": "veiculo_franqueiro"
    },
    {
        "id": "QACyKteVmxu63CjJBHPi",
        "href": "veiculo_garra"
    },
    {
        "id": "VY7htdYmnKKLiOqAvy66",
        "href": "veiculo_gigante"
    },
    {
        "id": "G1FbaC7zklBkyNk8dNJp",
        "href": "veiculo_gvm"
    },
    {
        "id": "Yb98hbs8UNT2BhtmTq5k",
        "href": "veiculo_hg"
    },
    {
        "id": "cMfHHEbFyq38jz9KlCw9",
        "href": "veiculo_imperio"
    },
    {
        "id": "Wh0zOSdTYKnhrOT1F2je",
        "href": "veiculo_jk"
    },
    {
        "id": "zwMCODmMooskpO6AKLEH",
        "href": "veiculo_karros"
    },
    {
        "id": "W5aJ8PN8HBmBmzaZ92W5",
        "href": "veiculo_marcioauto"
    },
    {
        "id": "igo4EwvE4niXFznop6qw",
        "href": "veiculo_mazzi"
    },
    {
        "id": "IY72hb2upt4ICiarHZBq",
        "href": "veiculo_melo"
    },
    {
        "id": "p437m80zZdn3LsVlYoDm",
        "href": "veiculo_minascar"
    },
    {
        "id": "Xfh7qZGaOYV3shStnTTp",
        "href": "veiculo_mpcar"
    },
    {
        "id": "UwibSMTogC2DaKjtXWYp",
        "href": "veiculo_multicar"
    },
    {
        "id": "7b05FpUvWrt3Y6HIQmKK",
        "href": "veiculo_oestecar"
    },
    {
        "id": "02r1DfHVxqhgltX9K9AY",
        "href": "veiculo_osnildo"
    },
    {
        "id": "Gg6KixqJP8XFGvCBHbeO",
        "href": "veiculo_pais"
    },
    {
        "id": "srGxXMCk4gPiFBP9rKqp",
        "href": "veiculo_paina"
    },
    {
        "id": "oCGEmX9HG86PnWEutkJg",
        "href": "veiculo_pirelli"
    },
    {
        "id": "CSJcopRNAJAcvdfXFlsP",
        "href": "veiculo_prado"
    },
    {
        "id": "s5IakyhUj03XYmrNuQzm",
        "href": "veiculo_primer"
    },
    {
        "id": "5TCdCat2OMsRaJyPFJWf",
        "href": "veiculo_rs"
    },
    {
        "id": "FN0m1O8fszHb4Vaw4YgN",
        "href": "veiculo_raga"
    },
    {
        "id": "WI77bWcbntvUfCwTGfGM",
        "href": "veiculo_realmatriz"
    },
    {
        "id": "7ufGMPIAccZ3S2ewr4sV",
        "href": "veiculo_realjp"
    },
    {
        "id": "imcrWzLaghyhM1XR6BVW",
        "href": "veiculo_renatim"
    },
    {
        "id": "On76mjQBbLTTM7nn9UHu",
        "href": "veiculo_renato"
    },
    {
        "id": "dTWHTOx3CDKLS9RwsYFP",
        "href": "veiculo_robertao"
    },
    {
        "id": "psxC92lHXEjFMhZsYVYy",
        "href": "veiculo_ccanever"
    },
    {
        "id": "SBh9mDmFmLQDSifd3trq",
        "href": "veiculo_cdcar"
    },
    {
        "id": "rZvbjxmKEIFccDGsuS9w",
        "href": "veiculo_cdsmotors"
    },
    {
        "id": "SP3u3VzRQmZjk56hOrWP",
        "href": "veiculo_cgarage"
    },
    {
        "id": "KQvV49X4MqgMQLEIsjtU",
        "href": "veiculo_cgramkar"
    },
    {
        "id": "Aa5EYFJ72u0NxZBxFnMs",
        "href": "veiculo_ckar"
    },
    {
        "id": "lZ0Vn8Jy9jdX4vZhQOl6",
        "href": "veiculo_clucas"
    },
    {
        "id": "uL48rfBNcbr7fnbDoyET",
        "href": "veiculo_cmea"
    },
    {
        "id": "FHS8FDsZCvVvIgT07UHL",
        "href": "veiculo_cmaverick"
    },
    {
        "id": "b5XYti4HOtwjDiQEFlvU",
        "href": "veiculo_ctops"
    },
    {
        "id": "D1TEY4ffvsyOf1ZGHaXl",
        "href": "veiculo_cambar"
    },
    {
        "id": "swAzpgLXRtlFcAAc8LUj",
        "href": "veiculo_stilo"
    },
    {
        "id": "DVmAEtsfJTT1IU6mHknx",
        "href": "veiculo_superauto"
    },
    {
        "id": "oewBLc6dFJ3wis0UQ6pZ",
        "href": "veiculo_supernovos"
    },
    {
        "id": "VhVN0mUWWam80XZE66ek",
        "href": "veiculo_somotos"
    },
    {
        "id": "f8UXCljsgWYOp7EwV1xL",
        "href": "veiculo_topatudo"
    },
    {
        "id": "cUPgCNLyfVhSt93bt6nh",
        "href": "veiculo_terra"
    },
    {
        "id": "169mxhCNZENd68wUnxCV",
        "href": "veiculo_titanium"
    },
    {
        "id": "oEjoQRpPsG9NhUTGhSeN",
        "href": "veiculo_topcristal"
    },
    {
        "id": "m32ebLWfZKPZ5sLChzbr",
        "href": "veiculo_topcar"
    },
    {
        "id": "KCuhNDPqa14IEOIQbzsn",
        "href": "veiculo_uniautos"
    },
    {
        "id": "TKKTNSJbla8T9yu9AbWp",
        "href": "veiculo_xmotors"
    },
    {
        "id": "BeQtmyFgOlhW9EZGijIu",
        "href": "veiculo_f3multimarcas"
    },
    {
        "id": "KNo9ILXsIOt7niXbYpjA",
        "href": "veiculo_newcarveiculos"
    },
    {
        "id": "amOzMlAApP5MQeGOsNDB",
        "href": "veiculo_alveiculos"
    },
    {
        "id": "xN0pIIfelTcSqQ573Q6W",
        "href": "veiculo_carmak"
    },
    {
        "id": "2mktiPmgbbCPpdaKj3Dn",
        "href": "veiculo_gabriel"
    },
    {
        "id": "oYOXFJlbRCSlW3mZRSyV",
        "href": "veiculo_itabirana"
    },
    {
        "id": "egZtPK0aol7FjR1rNJ4w",
        "href": "veiculo_marciol"
    },
    {
        "id": "oZUsIxuctUGFsq1QPCiJ",
        "href": "veiculo_m2"
    },
    {
        "id": "uhfRFnzECyWYuhsNYY6e",
        "href": "veiculo_moema"
    },
    {
        "id": "tKClABjMXoyoIj6j0yc4",
        "href": "veiculo_newcarveiculos2"
    },
    {
        "id": "a03oeTT24ruosaVgJQrM",
        "href": "veiculo_omega"
    },
    {
        "id": "RdqNeFLr3gWMi9mO7OWL",
        "href": "veiculo_primocar"
    },
    {
        "id": "nBeeoJtkZAEX0u9bEQ1u",
        "href": "veiculo_procar"
    },
    {
        "id": "MAj9GC2KHndrz0GLR4iu",
        "href": "veiculo_promocar"
    },
    {
        "id": "G3toicN1snxwDPDF7iZX",
        "href": "veiculo_racing"
    },
    {
        "id": "OyL7aCTC3X2sKnnzCkP0",
        "href": "veiculo_rofe"
    },
    {
        "id": "LEgZL7oULBdb6oFbjgxv",
        "href": "veiculo_schoppcar"
    },
    {
        "id": "FURcYSc4E5gu5GicqV0V",
        "href": "veiculo_spcar"
    },
    {
        "id": "XXrZSmAd71PaqWMAeHSJ",
        "href": "veiculo_ibiza"
    },
    {
        "id": "ROekOAqyxr1D4LNe6bXf",
        "href": "veiculo_consultorguilherme"
    },
    {
        "id": "CsBmxkuddVe51LDWZPSe",
        "href": "veiculo_consultorwilliam"
    },
    {
        "id": "XuXYADxwR6MOcQcXGnpH",
        "href": "veiculo_marciol2"
    },
    {
        "id": "wIeLYq4ktDYJGuszcyeJ",
        "href": "veiculo_rodrigol"
    },
    {
        "id": "LdTVjLpABGw3CcxvYKy7",
        "href": "veiculo_urus"
    }
]