import { useState } from "react";
import { Link } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <header className="fixed top-0 left-0 w-full z-50 shadow-md h-16">
            <nav className="flex flex-col sm:flex-row justify-between items-center gap-2 p-2 font-main font-medium bg-stone-50">
                <div className="flex justify-between items-center w-full sm:w-auto">
                    <Link to="/"><img src="/assets/logo.png" alt="Logo" className="h-12 pl-4 pt-2" /></Link>
                    <button onClick={toggleMenu} className="sm:hidden ml-2"><IoMdMenu size={32} /></button>
                </div>
                <div className="flex flex-col sm:flex-row sm:items-center sm:gap-8 w-full sm:ml-auto sm:justify-end sm:mr-20">
                    {["comprar", "sobre", "financiamento", "leiloes", "contatos"].map((path) => (
                        <Link key={path} to={`/${path}`} className={`${isMenuOpen ? "block" : "hidden"} sm:block transition-transform hover:scale-110 hover:font-semibold`}>
                            {path.charAt(0).toUpperCase() + path.slice(1)}
                        </Link>
                    ))}
                </div>
            </nav>
        </header>
    );
}
